<template>
  <div>
    <div class="success-container">
      <img src="../assets/success.png" alt="" />
      <div class="title">Yêu cầu trợ giúp của bạn đã được gửi đi</div>
      <div class="sub-title">
        Chúng tôi đã tiếp nhận yêu cầu và sẽ phản hồi lại cho bạn sớm nhất có
        thể
      </div>
    </div>
    <div class="button-container">
      <button @click="go('Home')" class="button button-confirm">
        Hoàn tất
      </button>
    </div>
  </div>
</template>


<script>
export default {
  methods: {
    go(name) {
      this.$router.push({
        name: name,
        query: { token: this.$route.query.token },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
  }
  .title {
    margin-top: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
  .sub-title {
    width: 70%;
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #7a8189;
    text-align: center;
  }
}
.button-container {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 20px;
  width: 100%;
  position: absolute;
  bottom: 20px;
  .button {
    width: 100%;
    text-align: center;
    border-radius: 2px;
    padding: 15px 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    border: none;
    text-decoration: none;
    &-confirm {
      background: #ff8200;
      color: white;
    }
  }
}
</style>