<template>
  <div class="modify-container">
    <div class="page-title">Chỉnh sửa thông tin cá nhân</div>
    <div>
      <div v-for="field in fields" :key="field.label" class="input-section">
        <label v-if="data[field.type].isShow" class="input-label">{{ field.label }}</label>
        <div v-if="data[field.type].isShow" class="input-field">
          <input
            @focus="onFocus(field.type)"
            @blur="onBlur(field.type)"
            v-model="data[field.type].value"
            type="text"
            name="fname"
          />
          <v-icon
            @click="remove(field.type)"
            class="remove-icon"
            v-show="data[field.type].isFocus"
            >cancel</v-icon
          >
        </div>
      </div>
    </div>
    <p class="error">{{ message }}</p>
    <div class="button-container">

      <button class="button button-report link" @click="go('Home')">Hủy</button>
      <button class="button button-confirm" variant="primary" @click="modify">
        Xác nhận thay đổi
      </button>
      <Modal v-show="isModalShow" @close="closeModal">
        <div class="modal-content">
          <div>
            <img src="../assets/modify-success.png" alt="" />
          </div>
          <div class="modal-title">Thông tin chỉnh sửa đã gửi thành công</div>
          <div class="modal-sub-title">
            Chúng tôi đã tiếp nhận yêu cầu chỉnh sửa thông tin và sẽ phản hồi
            lại cho bạn sớm nhất có thể
          </div>
          <button
            class="button button-confirm modal-button"
            @click="go('Home')"
          >
            Hoàn tất
          </button>
        </div>
      </Modal>
    </div>
    <Loading :active.sync="isLoading"
             color="#FF6F00"
             :is-full-page="true"></Loading>
  </div>
</template>

<script>
import Modal from "../components/Modal";
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  props: {
    userInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  components: {
    Modal,
    Loading
  },
  data() {
    return {
      isModalShow: false,
      message: "",
      is_error: false,
      isLoading: false,
      fields: [
        {
          type: "name",
          label: "Họ và tên",
        },
        {
          type: "id",
          label: "Số CMND / CCCD",
        },
        {
          type: "taxId",
          label: "Mã số thuế",
        },
        {
          type: "momoId",
          label: "Số điện thoại đăng ký momo"
        }
      ],
      data: {
        name: {
          value: "",
          isFocus: false,
          isShow: true
        },
        taxId: {
          value: "",
          isFocus: false,
          isShow: true
        },
        id: {
          value: "",
          isFocus: false,
          isShow: true
        },
        momoId: {
          value: "",
          isFocus: false,
          isShow: false
        }
      },
      status: '',
    };
  },
  methods: {
    go(name) {
      this.$emit("getData");
      this.$router.push({
        name: name,
        query: { token: this.$route.query.token },
      });
    },
    remove(type) {
      this.data[type].value = "";
    },
    modify() {
      let data = {
        id_number: this.data.id.value,
        tax_id: this.data.taxId.value,
        name: this.data.name.value,
        momo_id: this.data.momoId.value,
        token: this.$route.query.token,
      };
      this.is_error = false
      this.isLoading = true
      fetch(process.env.VUE_APP_MODIFY_API, {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((re) => re.json())
        .then((res) => {
          if (res.message !== "Success") {
            this.is_error = true
            this.message = res.message
          }else {
            this.openModal();
          }
          this.isLoading = false
        })
        .catch((er) => {
          this.is_error = true
          this.isLoading = false
          this.message = er.message
        });
    },
    onFocus(type) {
      this.data[type].isFocus = true;
    },
    onBlur(type) {
      setTimeout(() => {
        this.data[type].isFocus = false;
      }, 1000);
    },
    openModal() {
      this.isModalShow = true;
    },
    closeModal() {
      this.isModalShow = false;
    },
  },
  mounted() {
    this.data.name.value = this.userInfo.name;
    this.data.id.value = this.userInfo.id_number;
    this.data.taxId.value = this.userInfo.tax_id;
    this.data.momoId.value = ''
    if(this.userInfo.account_status === 'SUSPENDED') {
      this.data.momoId.isShow = true
    }
  },
};
</script>

<style lang="scss" scoped>
.modify-container {
  margin: 20px;
  .page-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1a1a1a;
    margin-bottom: 20px;
  }
}
input:focus {
  border: 1px solid #1a1a1a !important;
  outline: none !important;
}
.input-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  .input-label {
    margin-bottom: 5px;
  }
  .input-field {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    input {
      border: 1px solid #bcbcbc;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 10px;
      width: 100%;
    }
    .remove-icon {
      position: absolute;
      top: 20%;
      right: 10px;
      color: #bcbcbc;
    }
  }
}
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 90%;
  position: absolute;
  bottom: 20px;
  .button {
    width: 47%;
    text-align: center;
    border-radius: 2px;
    padding: 15px 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    border: none;
    &-report {
      background: #f2f2f2;
      color: #142246 !important;
    }
    &-confirm {
      background: #ff8200;
      color: white;
    }
  }
}
.link {
  text-decoration: none;
}
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .modal-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #1a1a1a;
    margin: 10px 0;
  }
  .modal-sub-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #7a8189;
    text-align: center;
    width: 80%;
    margin-bottom: 20px;
  }
}
.modal-button {
  width: 100% !important;
}
a {
  text-decoration: none;
}
.error {
  color: red;
}
</style>