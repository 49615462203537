<template>
  <div>
    <v-app-bar color="white" dense class="app-bar">
      <v-app-bar-nav-icon @click="goBack">
        <v-icon>keyboard_arrow_left</v-icon>
      </v-app-bar-nav-icon>
      <div>Những câu hỏi thường gặp</div>
    </v-app-bar>
    <v-expansion-panels class="question-list" multiple>
      <v-expansion-panel
        v-for="(item, i) in questionList"
        :key="i"
        class="question-item"
      >
        <v-expansion-panel-header class="tab-button-header">
          <div class="header-text">
            {{ item.question }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="body-text">
            {{ item.answer }}
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: {
    questionList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}
.body-text {
  font-size: 14px;
  line-height: 20px;
  color: #7a8189;
}
.question-list {
  margin-top: 10px;
  padding: 0 10px;
  .question-item {
    border: none !important;
    border-bottom: 1px solid #f2f2f2 !important;
  }
}
.v-expansion-panel::before {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%) !important;
}
.app-bar {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15) !important;
  background: none !important;
}
.tab-button-header {
  box-shadow: none !important;
  border: none !important;
}
// }
</style>