<template>
  <div id="app">
    <transition name="fade">
      <router-view
        :userInfo="userInfo"
        :questionList="questionList"
        :reportList="reportList"
        @getData="getData"
      />
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {
        // stt: 3,
        // tax_id: "0100889135",
        // driver_income: 87147590.0,
        // net_bonus_pit1: 341600.0,
        // gross_bonus_pit1: 345051.0,
        // pit45: 3921642.0,
        // pit1: 3451.0,
        // total_driver_income: 87492641.0,
        // total_pit_receivable: 3925092.0,
        // pit_refund: 3925092.0,
        // m100: 0,
        // range_tax: 9,
        // name: "Lê Trường Sinh",
        // city_id: "HAN",
        // tags: '["FL", "TEST_QR"]',
        // id_card: "012067836",
        // supplier_id: "84935554224",
        // account_status: "ACTIVATED",
        // request_status: "CONFIRMING",
        // contract_url:
        //   "https://www.figma.com/file/coLhxvo8Ao5ZPpH51hxmqc95/AhaDriver-iOS?node-id=3409%3A10561",
      },
      questionList: [
        {
          question: "Thuế thu nhập cá nhân là gì?",
          answer:
            "Thu nhập cá nhân trong kinh tế là thuật ngữ đề cập đến tất cả các khoản thu nhập của một cá nhân kiếm được trong một niên độ thời gian nhất định từ tiền lương, đầu tư và các khoản khác, nó là tổng hợp của tất cả các thu nhập thực nhận bởi tất cả các cá nhân hoặc hộ gia đình.",
        },
        {
          question: "Tôi làm part-time có cần phải nộp thuế cá nhân không?",
          answer:
            "Thu nhập cá nhân trong kinh tế là thuật ngữ đề cập đến tất cả các khoản thu nhập của một cá nhân kiếm được trong một niên độ thời gian nhất định từ tiền lương, đầu tư và các khoản khác, nó là tổng hợp của tất cả các thu nhập thực nhận bởi tất cả các cá nhân hoặc hộ gia đình.",
        },
        {
          question: "Tại sao phải nộp thuế cá nhân?",
          answer:
            "Thu nhập cá nhân trong kinh tế là thuật ngữ đề cập đến tất cả các khoản thu nhập của một cá nhân kiếm được trong một niên độ thời gian nhất định từ tiền lương, đầu tư và các khoản khác, nó là tổng hợp của tất cả các thu nhập thực nhận bởi tất cả các cá nhân hoặc hộ gia đình.",
        },
        {
          question: "Tôi làm part-time có cần phải nộp thuế cá nhân không?",
          answer:
            "Thu nhập cá nhân trong kinh tế là thuật ngữ đề cập đến tất cả các khoản thu nhập của một cá nhân kiếm được trong một niên độ thời gian nhất định từ tiền lương, đầu tư và các khoản khác, nó là tổng hợp của tất cả các thu nhập thực nhận bởi tất cả các cá nhân hoặc hộ gia đình.",
        },
        {
          question: "Không nộp thuế có bị phạt tù không?",
          answer:
            "Thu nhập cá nhân trong kinh tế là thuật ngữ đề cập đến tất cả các khoản thu nhập của một cá nhân kiếm được trong một niên độ thời gian nhất định từ tiền lương, đầu tư và các khoản khác, nó là tổng hợp của tất cả các thu nhập thực nhận bởi tất cả các cá nhân hoặc hộ gia đình.",
        },
      ],
      reportList: [
        {
          id: "1",
          label: "Thông tin cá nhân không chính xác",
          value: "driver_info_is_not_correct",
        },
        {
          id: "2",
          label: "Chưa được hoàn thuế năm 2020",
          value: "driver_has_not_been_refuned",
        },
        {
          id: "3",
          label: "Không xuất được phiếu xác nhận thu nhập và thuế",
          value: "driver_can_not_request_income_certification",
        },
        {
          id: "4",
          label: "Vấn đề khác",
          value: "tax_others",
        },
      ],
    };
  },
  methods: {
    getData() {
      fetch(
        process.env.VUE_APP_TAX_API + "?token=" + this.$route.query.token,
        {}
      )
        .then((re) => {
          if (re.status != 200) {
            this.$router.push({
              name: "Notfound",
            });
          }
          return re.json();
        })
        .then((res) => {
          if (res) {
            this.userInfo = res;
          } else {
            this.$router.push({
              name: "Notfound",
            });
          }
        })
        .catch((er) => {
          this.$router.push({
            name: "Notfound",
          });
          console.log(er);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="scss">
#app {
  width: 100%;
  font-family: Roboto;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
