<template>
  <div class="income-summary-container">
    <div class="notice">
      <div class="notice-icon">
        <img src="../assets/info.png" alt="" />
      </div>
      <div class="notice-text">
        Nếu Quý Đối tác thuộc đối tượng được hoàn thuế, Quý Đối tác không cần
        xuất phiếu xác nhận thu nhập vẫn sẽ được Ahamove hoàn trả số tiền đã tạm
        trích giữ liên quan đến nghĩa vụ thuế năm 2021.
      </div>
    </div>
    <div class="user-info">
      <div class="left">
        <img src="../assets/avatar-placeholder.png" alt="user-avatar" />
      </div>
      <div class="right">
        <div class="right__title">Đối tác</div>
        <div class="right__content">{{ userInfo.name }}</div>
      </div>
    </div>
    <div class="income-item border-bottom">
      <div class="left">Số điện thoại đăng ký Tài xế Aha</div>
      <div class="right">{{ userInfo.supplier_id }}</div>
    </div>
    <div class="income-item border-bottom">
      <div class="left">Số CMND / CCCD</div>
      <div class="right">{{ userInfo.id_number }}</div>
    </div>
    <div class="income-item border-bottom">
      <div class="left">Năm cập nhật</div>
      <div class="right">2021</div>
    </div>
    <div class="income-item">
      <div class="left">Mã số thuế</div>
      <div class="right">{{ userInfo.tax_id }}</div>
    </div>
    <div class="divider"></div>
    <div class="income-item border-bottom">
      <div class="left">Doanh thu được chia từ cước phí giao hàng</div>
      <div class="right">đ{{ numberWithCommas(userInfo.driver_income) }}</div>
    </div>
    <div class="income-item">
      <div class="left">Các khoản hỗ trợ</div>
      <div class="right">
        đ{{ numberWithCommas(userInfo.gross_bonus_pit1) }}
      </div>
    </div>
    <div class="income-item section--high-light">
      <div class="left text--high-light">Tổng doanh thu chịu thuế</div>
      <div class="right text--high-light">
        đ{{ numberWithCommas(userInfo.total_income) }}
      </div>
    </div>
    <div class="divider"></div>
    <div class="income-item border-bottom">
      <div class="left">
        Khoản tạm trích giữ 4,5% trên doanh thu được chia từ cước phí giao hàng
      </div>
      <div class="right">đ{{ numberWithCommas(userInfo.pit45) }}</div>
    </div>
    <div class="income-item">
      <div class="left">
        Khoản tạm trích giữ 1% từ các khoản hỗ trợ
      </div>
      <div class="right">đ{{ numberWithCommas(userInfo.pit1) }}</div>
    </div>
    <div class="section--high-light">
      <div class="income-item section-item">
        <div v-if="userInfo.total_income > 100000000" class="left text-color--normal">
          Tổng số thuế phải đóng (đối với các khoản hỗ trợ từ ngày 1/10/2021 tính theo mức thuế 1.5%)
        </div>
        <div v-else class="left text-color--normal">Tổng số thuế phải đóng</div>
        <div class="right">
          đ{{ userInfo.total_income > 100000000 ? numberWithCommas(userInfo.pit_payable) : 0 }}
        </div>
      </div>
      <div class="income-item section-item">
        <div v-if="userInfo.total_income > 100000000" class="left text-color--normal">
          Tổng số tiền thuế Ahamove đã tạm trích giữ (đối với các khoản hỗ trợ từ ngày 1/10/2021 chỉ tính theo mức thuế 1%)
        </div>
        <div v-else class="left text-color--normal" >
          Tổng số tiền thuế AhaMove đã tạm trích giữ
        </div>
        <div class="right">
          đ{{ userInfo.total_income > 100000000 ? numberWithCommas(userInfo.total_pit_receivable) : numberWithCommas(userInfo.total_pit_receivable) }}
        </div>
      </div>
      <div class="income-item section-item">
        <div v-if="userInfo.total_income > 100000000" class="left text--high-light text-weight--normal">
          Tổng số tiền được AhaMove hoàn trả
        </div>
        <div v-else class="left text--high-light text-weight--normal">
          Tổng số tiền được AhaMove hoàn trả
        </div>
        <div class="right text--high-light">
          đ{{ userInfo.total_income > 100000000 ? 0 : numberWithCommas(userInfo.total_pit_receivable) }}
        </div>
      </div>
    </div>
    <div class="divider divider-height--light"></div>
    <div class="income-item">
      <div class="left text-color--normal">
        <a href="https://ahamove.com/ke-hoach-thuc-hien-nghia-vu-thue-nam-2021/">Những câu hỏi thường gặp</a>
      </div>
      <div class="right text-color--normal button-icon">
        <img src="../assets/arrow-right.png" alt="arrow-right" />
      </div>
    </div>
    <div class="divider divider-height--light"></div>
    <div class="small">
      Thông tin thuế được cập nhật từ 01/01/2021 - 31/12/2021
    </div>

    <div class="income-item button-container">
      <!-- <router-link class="button button-report link" to="/report">
        <div>Báo cáo vấn đề</div>
      </router-link> -->
      <button @click="go('Report')" class="button button-report link">
        Báo cáo vấn đề
      </button>
      <button v-if="delay > 0" class="button button-report">
        Thử lại sau {{delay}}s
      </button>
      <button
        v-else-if="
          userInfo.request_status == 'CONFIRMING' ||
          userInfo.request_status == 'COMFIRMING'
        "
        class="button button-report"
        :class="{ tooltip: userInfo.request_status == 'CONFIRMING' }"
      >
        Đang xác nhận
      </button>
      <button
          class="button button-report"
          v-else-if="userInfo.request_status == 'EXPORTED' && is_show_generate">
        Đang khởi tạo
      </button>
      <a
        v-else-if="userInfo.request_status == 'EXPORTED'"
        @click="open(userInfo.contract_url)"
        target="_blank"
        class="button button-confirm"
      >
        <button>Xem chứng từ</button></a
      >

      <button class="button button-confirm" @click="exportTax" v-else>
        Xuất phiếu xác nhận
      </button>
      <Modal v-show="showModal" @close="showModal = false">
        <div class="modal-content">
          <div class="modal__title">Xác nhận thông tin cá nhân</div>

          <div class="user-info">
            <div class="left">
              <img src="../assets/avatar-placeholder.png" alt="user-avatar" />
            </div>
            <div class="right">
              <div class="right__title">Đối tác</div>
              <div class="right__content"></div>
            </div>
          </div>
          <div class="income-item border-bottom">
            <div class="left">Số điện thoại đăng ký Tài xế Aha</div>
            <div class="right">{{ userInfo.name }}</div>
          </div>
          <div class="income-item border-bottom">
            <div class="left">Số CMND / CCCD</div>
            <div class="right">{{ userInfo.id_number }}</div>
          </div>
          <div class="income-item">
            <div class="left">Mã số thuế</div>
            <div class="right">{{ userInfo.tax_id }}</div>
          </div>
        </div>

        <div class="footer-button-container">
          <button class="button button-left" @click="go('Modify')">
            Chỉnh sửa thông tin
          </button>
          <button class="button button-right" @click="handleTaxButton">
            Xác nhận
          </button>
        </div>
      </Modal>
    </div>
    <Loading
      :active.sync="isLoading"
      color="#FF6F00"
      :is-full-page="true"
    ></Loading>
  </div>
</template>

<script>
import Modal from "../components/Modal";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  props: {
    userInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  components: {
    Modal,
    Loading,
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      delay: 0,
    };
  },
  computed: {
    is_show_generate: function () {
      return ((new Date(this.userInfo.export_time).getTime() + 30*60000) > new Date().getTime())
    }
  },
  methods: {
    exportTax() {
      this.showModal = true;
    },

    go(name) {
      this.$router.push({
        name: name,
        query: { token: this.$route.query.token },
      });
    },
    numberWithCommas(num) {
      return parseInt(num).toLocaleString();
    },
    handleTaxButton() {
      // alert(
      //   "Hiện tại hệ thống xuất chứng từ đang quá tải, xin vui lòng thử lại sau."
      // );
      // return "";
      let data = {
        token: this.$route.query.token,
      };

      this.isLoading = true;
      fetch(process.env.VUE_APP_CONSTRACT_API, {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((re) => re.json())
        .then((res) => {
          console.log(res);
          // window.location.href = res.contract_url
          // ;
          this.isLoading = false;
          this.$emit("getData")
        })
        .catch((er) => {
          this.is_error = true;
          this.isLoading = false;
          this.message = er.message;
          this.delay = 60;
          alert(
            "Hiện tại hệ thống xuất chứng từ đang quá tải, xin vui lòng thử lại sau."
          );

        });
      this.showModal = false;
    },
    open(contract_url) {
      // window.open(contract_url, '_system')
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        alert("Hệ thống quá tải, xin vui lòng thử lại sau");
      }, 320000);
      window.location.href = contract_url;
    },
  },
  watch: {
    delay: {
      handler(value) {

        if (value > 0) {
          setTimeout(() => {
            this.delay--;
          }, 1000);
        }

      },
      immediate: true // This ensures the watcher is triggered upon creation
    }

  }
};
</script>

<style lang='scss' scoped>
$high-light: #ff8200;
.notice {
  background: rgba(254, 241, 217, 0.35);
  border: 1px dashed #ff8200;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 20px 10px;
  padding: 10px;
  display: flex;
  &-icon {
    margin-right: 10px;
  }
  &-text {
    font-size: 14px;
    line-height: 20px;
    color: #1a1a1a;
  }
}

.button {
  width: 47%;
  text-align: center;
  border-radius: 2px;
  padding: 15px 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  border: none;
}
.income-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  margin: 5px 5px;
  padding: 7px 5px;
  .left {
    color: #7a8189;
    max-width: 65%;
  }
  .right {
    font-weight: 500;
    max-width: 30%;
  }
  .text--high-light {
    font-weight: 500;
    color: $high-light;
  }
  .text-weight--normal {
    font-weight: normal !important;
  }
  .text-color--normal {
    color: black;
  }
  .button {
    width: 47%;
    text-align: center;
    border-radius: 2px;
    padding: 15px 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    border: none;
    &-report {
      background: #f2f2f2;
      color: #142246 !important;
    }
    &-confirm {
      background: #ff8200;
      color: white;
    }
  }
  .button-icon {
    font-weight: 700;
  }
}
.income-summary-container {
  .section--high-light {
    background: rgba(255, 130, 0, 0.05);
    border-radius: 4px;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
    .section-item {
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .border-bottom {
    border-bottom: solid 1px #f2f2f2;
  }
  .divider {
    height: 6px;
    background: #f2f2f2;
    margin: 10px 0;
  }
  .divider-height--light {
    height: 1px;
  }
  .small {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    margin: 5px 10px;
    padding: 5px 0;
  }
  .background--high-light {
    background-color: $high-light;
  }
}
.modal-content {
  .modal__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    // margin-bottom: 15px
    margin: 0 0 15px 10px;
  }
}
.user-info {
  display: flex;
  justify-content: flex-start;
  margin: 5px 10px;
  padding: 7px 0;
  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 10px;
    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    &__content {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
  }
}
.footer-button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  .button {
    &-left {
      width: 47%;
      color: #142246;
      background: #f2f2f2;
      a {
        color: #142246 !important;
        text-decoration: none;
      }
    }
    &-right {
      width: 47%;
      background: #ff8200;
      color: white;
    }
  }
}
a {
  text-decoration: none;
  color: black;
}
button:focus {
  outline: none;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>