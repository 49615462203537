<template>
  <div>
    <v-app-bar color="white" dense class="app-bar">
      <v-app-bar-nav-icon @click="goBack">
        <v-icon>keyboard_arrow_left</v-icon>
      </v-app-bar-nav-icon>
      <div>Báo cáo vấn đề</div>
    </v-app-bar>
    <div class="report-container">
      <v-radio-group v-model="selection" class="report-top">
        <v-radio
          v-for="report in reportList"
          :key="report.id"
          :value="report"
          color="#FF8200"
        >
          <template v-slot:label>
            <div class="report-label">{{ report.label }}</div>
          </template>
        </v-radio>
      </v-radio-group>
      <div class="report-bottom">
        <div>Mô tả vấn đề của bạn</div>
        <textarea
          placeholder="Để Ahamove hỗ trợ bạn tốt hơn, vui lòng miêu tả chi tiết vấn đề mà bạn gặp phải"
          rows="5"
          class="text-input"
          v-model="message"
        ></textarea>
      </div>
    </div>
    <div class="button-container">
      <button class="button" @click="report" :class="(!isReport) ? 'button-confirm' : 'button-report'">
        Gửi báo cáo vấn đề
      </button>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    reportList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      selection: "",
      message: ""
    };
  },
  computed: {
    isReport() {
      if (this.selection != "" && this.message != ""){
        return false
      }
      return true
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    go(name) {
      this.$router.push({
        name: name,
        query: { token: this.$route.query.token },
      });
    },
    report() {
      let data = {
        subject: "Thuế - " + this.selection.label,
        type: "question",
        description: this.message,
        contact_reason: "driver_-_tax",
        sub_contact_reason: this.selection.value
      };
      // console.log(data)
      this.selection = "";
      this.message = "";
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.$route.query.token
      };
      fetch(process.env.VUE_APP_REPORT, {
        method: "POST",
        body: JSON.stringify(data),
        headers: headers
      })
          .then((re) => re.json())
          .then((res) => {
            console.log(res)
            this.go("Success")
          })

    },
  },
};
</script>

<style lang="scss" scoped>
textarea:focus {
  outline: none;
}
.report-container {
  padding: 0 20px;
  .report-top {
    border-bottom: 1px solid #f2f2f2;
  }
  .report-bottom {
    width: 100%;
    margin-top: 15px;
    .text-input {
      margin-top: 15px;
      border: 1px solid #979797;
      box-sizing: border-box;
      border-radius: 3px;
      width: 100%;
      padding: 10px;
    }
  }
}

.app-bar {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15) !important;
  background: none !important;
}
.report-label {
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
  margin-left: 10px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 20px;
  width: 100%;
  position: absolute;
  bottom: 20px;
  .button {
    width: 100%;
    text-align: center;
    border-radius: 2px;
    padding: 15px 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    border: none;
    &-confirm {
      background: #ff8200;
      color: white;
    }
    &-report {
      background: #f2f2f2;
      color: #142246 !important;
    }
  }
}
.link {
  text-decoration: none;
}
</style>