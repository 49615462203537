import Vue from 'vue'
import VueRouter from 'vue-router'
import IncomeSummary from '../views/IncomeSummary.vue'
import Report from '../views/Report.vue'
import Qa from '../views/QuestionAnswer.vue'
import Modify from '../views/ModifyInfo.vue'
import Success from '../views/Success.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: IncomeSummary
  },
  {
    path: '/report',
    name: 'Report',
    component: Report
  },
  {
    path: '/qa',
    name: 'Qa',
    component: Qa
  },
  {
    path: '/success',
    name: 'Success',
    component: Success
  },
  {
    path: '/modify',
    name: 'Modify',
    component: Modify
  },
  {
    path: '/notfound',
    name: 'Notfound',
    component: () => import(/* webpackChunkName: "about" */ '../views/Notfound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
